body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  color: #4c4c4c;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
}

ul {
  list-style: none;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  color: #2B3C89;
}

section {
  scroll-margin-top: 80px; /* 80px = your header height */
}
